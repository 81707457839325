import React from "react"
import AccountGroupIcon from "mdi-react/AccountGroupIcon"

import CellPhoneIcon from "mdi-react/CellPhoneIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import jobTraining from "../../images/components/job-training.jpg"
import jobTrainingBody1 from "../../images/components/job-training-body-1.png"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./job-training-and-placement-program.scss"

var JobTrainingAndPlacementProgram = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="job-training-and-placement-program-page">
      <Banner
        src={jobTraining}
        title="Job Training and Placement Program"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="30%"
      />
      <PageBody className="page">
        <BorderTitle>
          Job Training and Placement Program
        </BorderTitle>
        <div className="body">
          <img className="left" src={jobTrainingBody1} alt="" /><p>In partnership with the Jacksonville Urban League (JUL), we want to assist the unemployed or underemployed family members who live with or near our participating seniors.  This is a big step that also complements our Financial Literacy and Asset Security component.  In this part, we hope to give these family members the skills they need to be hired and sustain their employment at a job they want that pays a living wage salary.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/mobile-phone-use-training-for-seniors"
            className="first"
            icon={CellPhoneIcon}
            title="Mobile Phone-Use Training for Seniors"
            />
          <ComponentNavigator
            next
            link="/components-year-two/food-security-and-social-connection-app"
            icon={AccountGroupIcon}
            title="Food Security and Social Connection App"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default JobTrainingAndPlacementProgram
